import React from "react";
import { Link } from "react-router-dom";

const Res = () => {
  return (
    <>
     {/*  commercial Developmen */}
      <section id="commercial">
        <div className="container mb-2 ">
          <div className="row">
            <div className="col-12 ">
              <h2 className=" radiant mt-4 mb-4">New Commercial Development,<br />
                Offers For The Citizens  Resident</h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div id="carouselExampleCaptions1" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/c1.jpeg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                              SS Whitewater <span className="mt-2"> Sector 90,
                                  Gurgoan</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p> Where Fashion, Fun, and Flavor Converge in Sector 90, New Gurugram! ...

                              
                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/c2.jpg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                              ⁠Trehan Iris Broadway <span className="mt-2">sector 85, Gurgaon</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p> Ready to move commercial project in sector 85
                               Gurgaon, offering a mix of modern shops, shopping...                              
                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/c3.jpeg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                              ⁠AIPL Joy District <span className="mt-2">Sector 88, Gurgoan</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p> AIPL Joy District offers premium retail shops and assured returns.
                               Located just 1 KM from NPR...
                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/c4.jpg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                              ⁠Elan The Mark 
                                <span className="mt-2"> Sector 106, Gurgoan</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p> Elan The Mark - Retail shop, office & food court space, multiplex for sale in sector 106,
                               Dwarka...</p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* More carousel items */}
          </div>
          {/* Previous Button */}
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>

          {/* Next Button */}
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      <section>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/c5.jpg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                              ⁠Emaar Business District  <span className="mt-2 ">Sector 75A, Gurgoan </span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p>Emaar Business District 75A is a commercial flagship project in Gurgaon. It is a modern and...

                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/c6.jpg" alt="194x228" className=" w-100 im-fluid" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href>
                              ⁠M3M Paragon
                                <span className="mt-2 "> Sector 57, Gurgoan</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p> An expansive 3.16-acre development, M3M Paragon 57 offers an excellent mix of world
                               ...</p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/c7.jpg" alt="194x228" className="w-100" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <h5 className="name">
                              <span class> <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a></span>
                              <a href> 
                              AIPL Joy Street
                                <span className="mt-2 "> Sector 66, Gurgoan</span>
                              </a>
                            </h5>
                          </div>
                          <div className="description">
                            <p>  AIPL Joy Street is an excellent project for both commercial and retail investments.
                               Developed ...

                            </p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <div className="product-content product-wrap clearfix">
                      <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                          <div className="product-image">
                            <img src="assets/images/c8.jpg" alt="194x228" className=" w-100 im-fluid" />
                            <span className="tag2 hot">
                              HOT
                            </span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-deatil">
                            <span class>
                              <h5 className="name">
                                <a href> <img src="assets/images/spcilofer.png" alt="" className="offeri" /></a>
                                <a href>
                                ⁠AIPL Joy Gallery
                                  <span className="mt-2 ">Sector 66, Gurgoan</span>
                                </a>
                              </h5>
                            </span></div>
                          <div className="description">
                            <p>AIPL Joy Gallery is a commercial project located in Sector 66, Gurgaon. Developed by AIPL ...</p>
                          </div>
                          <div className="product-info smart-form">
                            <div className="row">
                              <div className="col-7">
                                <a href className="btn btn-success w-100">Offers For You</a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-heart" /></a>
                              </div>
                              <div className="col-2">
                                <a href className="btn btn-danger"><i className="bi bi-share" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      {/* new slider */}
      <section className="mt-4 mb-2">

        <div id="carouselExampleCaptions2" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">SS Whitewater, sec 90, </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd">⁠Trehan Iris Broadway, Sec 85, </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd">⁠AIPL Joy District, Sec 88, </div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd">Elan The Mark, Sec 106,  </div>
                 
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row  justify-content-evenly">
                <div className="col-lg-3 col-12 mt-3  p-2" id="sd">⁠Emaar Business District, Sec 75A,  </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd">⁠M3M Paragon, Sec 57, </div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd"> ⁠AIPL Autograph, </div>
                  <div className="col-lg-2 col-12 mt-3  p-2" id="sd"> ⁠AIPL Joy Central, </div>
                  
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row  justify-content-evenly">
                <div className="col-lg-2 col-12 mt-3  p-2" id="sd">AIPL Joy Street, Sec 66, </div>
                <div className="col-lg-2 col-12 mt-3  p-2" id="sd"> ⁠AIPL Joy Gallery, Sec 66 </div>
                  <div className="col-lg-2 col-12 mt-3 p-2" id="sd">⁠M3M Atrium, Sec 57,</div>
                  <div className="col-lg-2 col-12 mt-3 p-2 " id="sd"> ⁠Grand Central, Sec 114,</div>
                  <div className="col-lg-2 col-12 mt-3 p-2 " id="sd">⁠Elan Paradise </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                
                  <div className="col-lg-3 col-12 mt-3 p-2 " id="sd">Signature DXP Luxuria, Sec 37D, </div>
                  <div className="col-lg-2 col-12 mt-3 p-2 " id="sd"> ⁠EBD, Sec 99,</div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd"> ⁠DLF Corporate Greens, Sec 74,  </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd"> ⁠M3M CAPITALWALK, Sec 113,  </div>
                  
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                <div className="col-lg-3 col-12 mt-3  p-2" id="sd"> M3M ROUTE 65, Sec 65,  </div>
                <div className="col-lg-4 col-12 mt-3  p-2" id="sd"> Signature Global Signum Plaza, Sec 93, </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd"> Elan Miracle, Sector-84, Gurgaon</div>
                
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      {/*  Residential Developmen */}
      <section id="residential">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h2 className="radiant mt-5 mb-4">New Residential Development,<br />
                Offers For The Citizens  Resident</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-12 ">
              <div className="row">
                <div className="col-lg-6 col-12 mt-4 ">
                  <div className="card ">
                    <img src="assets/images/r1.jpeg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <span className="n"> <Link>
                      Max Estates 360 | Sector 36A, Gurgaon
                      </Link></span>
                      <p className="card-text mt-2">
                   Max Estates Sector 36A Gurugram is a newly launched residential housing development project in Gurgaon. Offering 2/3/4 BHK luxury flats.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-4  ">
                  <div className="card">
                    <img src="assets/images/r2.jpeg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <span className="n"> <Link >
                      M3M Mansion | Sector 113, Gurgaon
                      </Link></span>
                      <p className="card-text mt-2">
                     M3M Mansion is a New Launch Luxury Residences in Sector 113 Gurgaon. It offers well-designed 2BHK,3BHK and 4BHK Luxury Apartments with luxury...

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-12 mt-4 ">
                  <div className="card ">
                    <img src="assets/images/r3.jpeg" className="card-img-top " alt="..." />
                    <div className="card-body">
                      <span className="n"> <Link>
                      Ganga Anantam | Sector 85, Gurgaon
                      </Link></span>
                      <p className="card-text mt-2">
                    Escape into the peaceful and sophisticating refuge of Ganga Realty luxury flats in Sector 85 of Gurugram. This is how you come back home which is filled with ...</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12  mt-4">
                  <div className="card ">
                    <img src="assets/images/r4.jpeg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <span className="n"> <Link>
                      Smartworld The Edition | Sector 66, Gurgoan 
                      </Link></span>
                      <p className="card-text mt-2">
                   Our premium residential complex offers 3.5 BHK and 4.5 BHK homes designed for contemporary families. Spanning 14 lush acres with 6 impressive towers, Smart ...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item bg-info">
                      <h2 className="accordion-header " id="headingOne">
                      </h2>
                      <h6 className="text-center mt-3 mb-3 text-uppercase">
                        Offers &amp; Discount</h6>
                      <button onclick="myFunction()" className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Residential, Sector - 79 Gurgoan
                      </button>
                      <ul className="list-group  text-start">
                        <li className="list-group-item">1.  M3M Golf Hills </li>
                        <li className="list-group-item"> 2. Signature Global City ... <span id="hide" /></li>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <li className="list-group-item">3.  M3M Antalya Hills </li>
                            <li className="list-group-item"> 4. Max Estates 360</li>
                            <li className="list-group-item"> 5. M3M Mansion  </li>
                            <li className="list-group-item"> 6. Smartworld The Edition</li>
                            <li className="list-group-item"> 7. Ganga Nandaka </li>
                            <li className="list-group-item"> 8. Signature Global Titanium SPR </li>
                            <li className="list-group-item"> 9.  M3M Golf Hills </li>
                            <li className="list-group-item"> 10. BPTP The Amaario </li>
                            <li className="list-group-item"> 11. Krisumi Waterfall Residences </li>
                            <li className="list-group-item"> 12. Navraj The Antalya</li>
                            <li className="list-group-item"> 13. M3M Crown </li>
                            <li className="list-group-item"> 14. Whiteland The Aspen </li>
                          </div>
                        </div>
                      </ul>
                    </div>
                    <div className="accordion-item mt-5">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Yamuna Expressway
                        </button>
                      </h2>
                      <ul className="list-group  text-start">
                        <li className="list-group-item">1.Ashiana Amarah </li>
                        <li className="list-group-item">2. Godrej Zenith </li>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <li className="list-group-item"> 3.Signature Global City </li>
                            <li className="list-group-item"> 4.Godrej Aristocrat</li>
                            <li className="list-group-item">5. Sobha Altus </li>
                            <li className="list-group-item">6.MRG Crown </li>
                            <li className="list-group-item"> 7. M3M Antalya Hills </li>
                            <li className="list-group-item">8. Godrej 103 </li>
                            <li className="list-group-item"> 9. Signature Global Deluxe DXP </li>
                          </div>
                        </div>
                      </ul></div>
                    <div className="accordion-item mt-5 ">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Gurgoan Extension
                        </button>
                      </h2>
                      <ul className="list-group  text-start">
                        <li className="list-group-item"> 1. BPTP The Amaario  </li>
                        <li className="list-group-item">2.Signature Global Deluxe DXP  </li>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <li className="list-group-item">3.  M3M Golf Hills </li>
                            <li className="list-group-item"> 4. Max Estates 360</li>
                            <li className="list-group-item"> 5. M3M Mansion  </li>
                            <li className="list-group-item"> 6. Smartworld The Edition</li>
                            <li className="list-group-item"> 7. M3M Antalya Hills </li>
                            <li className="list-group-item">8. Godrej 103 </li>
                            <li className="list-group-item"> 9. Signature Global Deluxe DXP </li>
                        
                          </div>
                        </div>
                      </ul></div>
                  </div>
                </div>
                <div className="col-12 mt-5">
                  <div className="accordion" id="accordionExample2">
                    <div className="accordion-item bg-info">
                      <h6 className="accordion-header" id="heading2">
                      </h6><h5 className=" mt-3 mb-3  text-center text-uppercase">
                        Offers &amp; Discount</h5>
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                        Commercial, Gurgoan
                      </button>
                      <ul className="list-group  text-start">
                        <li className="list-group-item"> 1. Signature Global Deluxe DXP 
                        </li>
                        <li className="list-group-item">2. M3M Antalya Hills </li>
                        <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample2" style={{}}>
                          <div className="accordion-body">
                          <li className="list-group-item">3.  Godrej 103 </li>
                            <li className="list-group-item"> 4. Max Estates 360</li>
                            <li className="list-group-item"> 5. M3M Mansion  </li>
                            <li className="list-group-item"> 6. Smartworld The Edition</li>
                            <li className="list-group-item"> 7. Ganga Nandaka </li>
                            <li className="list-group-item"> 8. Signature Global Titanium SPR </li>
                            <li className="list-group-item">9.  M3M Golf Hills </li>
                            <li className="list-group-item"> 10. BPTP The Amaario </li>
                            <li className="list-group-item"> 11. Krisumi Waterfall Residences </li>
                            <li className="list-group-item"> 12. Navraj The Antalya</li>
                            <li className="list-group-item"> 13. M3M Crown </li>
                            <li className="list-group-item"> 14. Whiteland The Aspen </li>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* new slider2 */}
      <section className="mt-5 mb-5">
        <div id="carouselExampleCaptions4" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2"> Max Estates 360 Sector 36A, Gurgaon
                   </div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">M3M Mansion Sector 113, Gurgaon
                  </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">Ganga Anantam Sector 85, Gurgaon

                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row  justify-content-evenly">
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2"> Smartworld The Edition Sector 66, Gurgoan </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2"> Ganga Nandaka, Sector 84, Gurgaon
                  </div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2"> Signature Global Titanium SPR, Sector 71, Gurgoan
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row  justify-content-evenly">
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2"> M3M Golf Hills, Sector 79, Gurgaon
                  </div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2"> BPTP The Amaario, Sector 37D, Gurgaon</div>
                  <div className="col-lg-4 col-12 mt-3 p-2" id="sd2"> Krisumi Waterfall Residences, Sector 36A, Gurgaon </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-4 col-12 mt-3 p-2 " id="sd2"> Navraj The Antalya, Sector 37D, Gurgaon 
                  </div>
                  <div className="col-lg-3 col-12 mt-3 p-2 " id="sd2"> M3M Crown, Sector 111, Gurgaon 
                  </div>
                  <div className="col-lg-4 col-12 mt-3 p-2 " id="sd2"> Whiteland The Aspen, Sector 76, Gurgaon

                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2"> Ashiana Amarah, Sector 93, Gurgaon

                  </div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">Godrej Zenith, Sector 89, Gurgaon
                  </div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">  Signature Global City 79B, Sector 79, Gurgaon

                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2"> Godrej Aristocrat, Sector 49, Gurgaon

                  </div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">Sobha Altus, Sector 106, Gurgaon</div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">MRG Crown, Sector 106, Gurgaon
                  </div>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <div className="row justify-content-evenly">
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2"> M3M Antalya Hills, sector 79, Gurgaon </div>
                  <div className="col-lg-3 col-12 mt-3  p-2" id="sd2">  Godrej 103, Sector 103, Gurgaon
                  </div>
                  <div className="col-lg-4 col-12 mt-3  p-2" id="sd2">Signature Global Deluxe DXP, Sector 37D, 
                  </div>
                </div>
              </div>
            </div>
           

          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions4" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions4" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>


      </section>
    </>

  );
}

export default Res;