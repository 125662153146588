import React from "react";
import { Link } from "react-router-dom";

const Landmarks = () => {
  return (
    <>
      <section>
        <div className="container mt-1">
          <div className="row">
            <div className="col-12 ">
              <h2 className=" ms-2 radiant mt-4 mb-3">Landmarks Near
                The Citizens </h2>
            </div>
          </div>
          <div className="row" id="hos">
            <div className="col-lg-7 col-12">
              <div className="row">
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center"> Hospital </h5>
                    <hr />
                    <a href="https://www.medanta.org/" target="_blank" rel="noopener noreferrer">1. &nbsp; Medanta-The Medicity </a>
                    <hr />
                    <Link data-bs-toggle="modal" data-bs-target="#exampleMod" >
                    2. &nbsp; Fortis Memorial Resea... <span className="text-danger"><b>See All</b></span></Link>
                  </div>
                </div>
               
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Educational Institute
                    </h5>
                    <hr />
                    <a href="https://www.bmu.edu.in/"   target="_blank" rel="noopener noreferrer" >
                      1. &nbsp; BML Munjal University
                    </a>
                    <hr />
                    <Link  data-bs-toggle="modal" data-bs-target="#exampleMo">2. &nbsp;  GD Goenka University ...<span className="text-danger"><b>See All</b></span>
                    </Link>
                  </div>
                </div>
               
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Transportation Hub
                    </h5>
                    <hr />
                    <a href="https://www.delhimetrorail.com/line/LN2" target="_blank" rel="noopener noreferrer"  >1. &nbsp;  Huda City Centre Gurgoan</a>

                    <hr />
                    <Link  data-bs-toggle="modal" data-bs-target="#Transportation">2. &nbsp; Gurgoan Bus Stand...<span className="text-danger"><b>See All</b></span>
                    </Link>
                  </div>
                </div>
              
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Shopping Centre
                    </h5>
                    <hr />
                    <a href="https://www.dlfcyberhub.com/" target="_blank" rel="noopener noreferrer" >1. &nbsp; DLF Cyber Hub 
                    </a>
                    <hr />
                    <Link  data-bs-toggle="modal" data-bs-target="#examplesh">2. &nbsp; Ambience
                      Mall...<span className="text-danger"><b>See All</b></span></Link>
                  </div>
                </div>
               
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Commute
                    </h5>
                    <hr />
                    <a href="https://en.wikipedia.org/wiki/Kingdom_of_Dreams" target="_blank" rel="noopener noreferrer" >1. &nbsp; Kingdom of Dreams </a>
                    <hr />
                    <Link   href  data-bs-toggle="modal" data-bs-target="#exampleco" > 2. &nbsp; Cyber Hub...... <span className="text-danger"> <b>See
                    All</b></span> </Link>
                  </div>
                </div>
               
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Restaurants
                    </h5>
                    <hr />
                    <a href="https://www.pregowestingurgaon.com/"  target="_blank" rel="noopener noreferrer">1. &nbsp; Prego Restaurant </a>
                    <hr />
                    <Link data-bs-toggle="modal" data-bs-target="#Restaurants">2. &nbsp; 21 Gun Salute Restaurant...<span className="text-danger"><b>See All</b></span></Link>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="col-lg-5 col-12">
                 
              <div className="card p-4 mt-4" style={{ backgroundColor: '#F1F4FFFF' }}  >

              <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}>Bank &amp; ATM.... </span><span style={{ color: 'red' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>+2 More...</b></span></a>
              <hr />
              <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}>Doctors Clinic ....</span> <span style={{ color: 'red' }}>
                <b>+2
                  More...</b></span></a>
              <hr />
              <a href> <i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}> Best Jewellery Showrooms... </span><span style={{ color: 'red' }}> <b>+2 More...</b></span></a>
              <hr />
              <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}> <span> </span>Car Showroom
                &amp; Service Center .... </span><span style={{ color: 'red' }}> <b>+2
                  More...</b></span></a>
              <hr />
              <a href> <i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}> Beauty &amp; Unisex Salon...</span> <span style={{ color: 'red' }}> <b>+2 More...</b></span></a>
              <hr />
              <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}>Chemist Shop .... </span><span style={{ color: 'red' }}>
                <b>+2
                  More...</b></span></a>
              <hr />
              <a href> <i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}> Verified Property Dealers...</span> <span style={{ color: 'red' }}><b>+2 More...</b></span></a>
              <hr />
              <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}>Residential Or Commercial Property...</span> <span style={{ color: 'red' }}><b>+2 More...</b></span></a>
              <hr />
              <a href style={{ color: 'red' }}><b>See all</b> &nbsp;<i className="bi bi-arrow-right" /></a>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )

}
export default Landmarks;